<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Setting</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="setting_list"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
          <v-spacer></v-spacer>

          <span style="width: 20px"></span>
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on">Add Setting</v-btn>
                        </template> -->

            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.setting_master"
                          label="Enter Setting"
                          :rules="[(v) => !!v || 'required']"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="editedItem.setting_description"
                          label="Description"
                        >
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.setting_value"
                          label="Value"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          dense
                          v-model="editedItem.isactive"
                          color="success"
                          label="Active"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary darken-1" dark @click="save"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)"
              >edit</v-icon
            >
          </v-avatar>
          <!-- <v-btn text> <v-icon color="red" @click="deletefunc(item.id)"> mdi-delete</v-icon></v-btn> -->
        </div>
      </template>
      <template v-slot:item.isactive="{ item }">
        <v-switch
          v-model="item.isactive"
          @change="activeswitch(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    search: "",
    dialog: false,
    valid: false,
    setting_list: null,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    errorMsg: {
      id: false,
    },

    headers: [
      {
        text: "Setting",
        align: "left",
        sortable: false,
        value: "setting_master",
      },
      {
        text: "Description",
        align: "left",
        sortable: false,
        value: "setting_description",
      },
      {
        text: "Value",
        align: "left",
        sortable: false,
        value: "setting_value",
      },

      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],
    setting_list: [],
    editedIndex: -1,

    editedItem: {
      id: "",
      setting_master: "",
      setting_description: "",
      setting_value: "",
      isactive: true,
    },
    defaultItem: {
      id: "",
      setting_master: "",
      setting_description: "",
      setting_value: "",
      isactive: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Setting" : "Edit Setting";
    },
  },
  watch: {
    dialog() {},
  },

  mounted() {
    this.onLoad();
    this.setting_list = res.data.setting_list;
  },
  methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
    },
    onLoad() {
      this.overlay = true;
      axios.get("/AdminMaster/addSetting").then((res) => {
        if (res.data.msg == "200") {
          this.setting_list = res.data.setting_list;
        }
      });
    },
    hasWhiteSpace(s) {
                var status = false;
                var reWhiteSpace = new RegExp(/^\s+$/);

                // Check for white space
                if (reWhiteSpace.test(s)) {
                    // alert("Please Check Your Fields For Spaces");
                    //   return true;
                    return /^\s/.test(s);
                }
                //   return false;
                return /^\s/.test(s);
            },

    save() {
      //  alert("updated"+this.editedItem.setting_master)
      
      if(this.hasWhiteSpace(this.editedItem.setting_description)){
        this.showSnackbar("red", "Please enter valid Description ");
        return;
      }
      if (this.editedIndex > -1) {
        // if (this.isValidated())
        //  {
        axios
          .post("/AdminMaster/editSetting", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              Object.assign(
                this.setting_list[this.editedIndex],
                this.editedItem
              );
              //    this.setting_list=res.data.setting_list
              this.showSnackbar("#4caf50", "Setting Updated Successfully..."); // show snackbar on success
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", "Setting Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });

        this.close();
        // }
      } else {
        //end
        alert("done" + this.editedItem.setting_master);
        alert("done" + this.editedItem.setting_description);
        alert("done" + this.editedItem.setting_value);
        axios
          .post("/AdminMaster/saveSetting", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              //  this.setting_list=res.data.setting_list
              this.setting_list.push(this.editedItem);
              this.showSnackbar("#4caf50", "Setting Added Successfully..."); // show snackbar on success
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", "Setting Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, // end of save()
    isValidated() {
      if (
        this.editedItem.id &&
        this.editedItem.setting_description &&
        this.editedItem.setting_value
      ) {
        return true;
      } else {
        if (!this.editedItem.id) {
          this.errorMsg.id = true;
        }

        return false;
      }
    }, // .....end of isValidated()

    deletefunc(item) {
      //console.log("a")
      const data = {
        deleteitem: item,
      };
      //  console.log("a1")
      // if(this.editedItem.setting_master){
      axios
        .post("/AdminMaster/deleteSetting", data)
        .then((res) => {
          //   console.log("a2")
          if (res.data.msg == "200") {
            //  this.setting_list=res.data.setting_list
            this.showSnackbar("#4caf50", "Deleted Successfully...");
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
      // }
    },

    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      // console.log(item.id);

      axios
        .post("/AdminMaster/activeSetting", data)
        .then((res) => {
          // console.log("res.data");
          // console.log(res.data);
          if (res.data.msg == "200") {
            this.setting_list = res.data.setting_list;
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem(item) {
      this.editedIndex = this.setting_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
